<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="12">
        <h2 style="font-weight: 800; color: #577eba">
          สรุปการแจ้งเบาะแสยาเสพติด
        </h2>
        <h2 style="color: #577eba">รายชื่อผู้ต้องสงสัย</h2>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_group"
          :items="m_groupItems"
          item-text="m_group"
          label="เลือก ปส."
          outlined
          dense
          hide-details="auto"
          @change="getm_area(m_group)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_area"
          :items="m_areaItems"
          item-text="m_area"
          label="เลือก ภาค"
          outlined
          dense
          hide-details="auto"
          @change="getm_province(m_area)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          v-model="m_province"
          :items="m_provinceItems"
          item-text="m_province"
          label="เลือก จังหวัด"
          outlined
          dense
          hide-details="auto"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="type_name"
          :items="itemtype"
          item-text="text"
          item-value="value"
          label="ประเภทข้อมูล"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="suspect_sex"
          :items="itemsex"
          item-text="text"
          item-value="value"
          label="เพศ"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <!-- <v-col cols="12" sm="4" md="4">
        <v-select label="เจ้าหน้าที่รัฐ" outlined dense hide-details="auto" />
      </v-col> -->
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="suspect_skin"
          :items="itemskin"
          item-text="text"
          item-value="value"
          label="สีผิว"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="suspect_job"
          :items="itemjob"
          item-text="text"
          item-value="value"
          label="อาชีพ"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <!-- <v-col cols="12" sm="4" md="4">
        <v-text-field
          label="เบอร์โทร, Socials"
          outlined
          dense
          hide-details="auto"
        />
      </v-col> -->
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              append-icon="mdi-calendar"
              label="วันที่เริ่มต้น"
              outlined
              dense
              hide-details="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date1"
              append-icon="mdi-calendar"
              label="ถึงวันที่"
              outlined
              dense
              hide-details="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-row class="mt-0 mr-1" justify="end">
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn @click="clear()">เคลียร์ </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn dark color="#577eba" @click="getAllData()">ค้นหา </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col class="text-right" cols="12" sm="4" md="4">
        <ExportTable2 :data="items" :formDate="date" :toDate="date1" />
      </v-col>
    </v-row>

    <v-data-table
      class="mt-3 elevation-1"
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{
        'items-per-page-options': [20, 30, 40, 50]
      }"
      :items-per-page="20"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <!-- <v-icon @click="ViewBanner(item)">mdi-eye</v-icon> -->
          <v-icon @click="UpdateBanner(item)" class="mx-2"
            >mdi-text-box-search-outline</v-icon
          >
          <!-- <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon> -->
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import ExportTable2 from "@/views/ExportExcel/ExportTable2.vue";
export default {
  components: {
    Loading,
    ExportTable2,
  },

  data: () => ({
    m_group: "",
    m_groupItems: [],
    m_area: "",
    m_areaItems: [],
    m_province: "",
    m_provinceItems: [],
    search: "",
    menu: false,
    menu1: false,
    // date: new Date("2022-01-01").toISOString().substr(0, 10),
    date: new Date("2022-01-01").toISOString().substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    headers: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อ", value: "suspect_name", align: "center" },
      { text: "นามสกุล", value: "suspect_lastname", align: "center" },
      { text: "จำนวนที่ถูกแจ้ง(ครั้ง)", value: "count", align: "center" },
      { text: "เพศ", value: "suspect_sex", align: "center" },
      // { text: "เจ้าหน้าที่รัฐ", value: "",align:"center" },
      { text: "สีผิว", value: "suspect_skin", align: "center" },
      // { text: "อาชีพ", value: "",align:"center" },
      // { text: "ข้อมูล", value: "action", align: "center" },
    ],
    items: [],

    suspect_sex: "",
    suspect_skin: "",
    suspect_job: "",
    itemsex: [
      { text: "ทั้งหมด", value: "" },
      { text: "ชาย", value: "ชาย" },
      { text: "หญิง", value: "หญิง" },
    ],
    itemskin: [
      { text: "ทั้งหมด", value: "" },
      { text: "ดำ", value: "ดำ" },
      { text: "ดำแดง", value: "ดำแดง" },
      { text: "ขาว", value: "ขาว" },
      { text: "ขาวเหลือง", value: "ขาวเหลือง" },
      { text: "เผือก", value: "เผือก" },
    ],
    itemjob: [
      { text: "ทั้งหมด", value: "" },
      { text: "อัยการ", value: "อัยการ" },
      { text: "ครู", value: "ครู" },
      { text: "พลเรือน", value: "พลเรือน" },
      { text: "ปกครอง(นายอำเภอ)", value: "ปกครอง(นายอำเภอ)" },
      { text: "ปกครอง(ปลัดอำเภอ)", value: "ปกครอง(ปลัดอำเภอ)" },
      { text: "ปกครอง(กำนัน)", value: "ปกครอง(กำนัน)" },
      { text: "ปกครอง(สารวัตรกำนัน)", value: "ปกครอง(สารวัตรกำนัน)" },
      { text: "ปกครอง(ผญบ.)", value: "ปกครอง(ผญบ.)" },
      { text: "ปกครอง(ผู้ช่วย ผญบ.)", value: "ปกครอง(ผู้ช่วย ผญบ.)" },
      { text: "ปกครอง(แพทย์ตำบล)", value: "ปกครอง(แพทย์ตำบล)" },
      { text: "ราชการท้องถิ่น", value: "ราชการท้องถิ่น" },
      { text: "ลูกจ้าง", value: "ลูกจ้าง" },
      { text: "เจ้าหน้าที่เทศบาล", value: "เจ้าหน้าที่เทศบาล" },
      { text: "ลูกจ้างประจำ", value: "ลูกจ้างประจำ" },
      { text: "ราชทัณฑ์", value: "ราชทัณฑ์" },
      { text: "เกษตรกร", value: "เกษตรกร" },
      { text: "ประมง", value: "ประมง" },
      { text: "นักเรียน นักศึกษา", value: "นักเรียน นักศึกษา" },
      { text: "ไม่ทราบ/ไม่ระบุอาชีพ", value: "ไม่ทราบ/ไม่ระบุอาชีพ" },
      { text: "สจ.", value: "สจ." },
      { text: "อส.(มท.)", value: "อส.(มท.)" },
      { text: "อส.(ทหารพราน)", value: "อส.(ทหารพราน)" },
      {
        text: "สนง.คณะกรรมการการอาชีวศึกษา",
        value: "สนง.คณะกรรมการการอาชีวศึกษา",
      },
      { text: "อบต.", value: "อบต." },
      { text: "ตำรวจ", value: "ตำรวจ" },
      { text: "อบจ.", value: "อบจ." },
      { text: "อสม.", value: "อสม." },
      { text: "กรมการปกครอง", value: "กรมการปกครอง" },
      { text: "พระสงฆ์/นักบวช", value: "พระสงฆ์/นักบวช" },
      { text: "นักการภารโรง", value: "นักการภารโรง" },
      { text: "กรมป่าไม้", value: "กรมป่าไม้" },
      { text: "สนง.เขต", value: "สนง.เขต" },
      { text: "ค้าขาย", value: "ค้าขาย" },
      { text: "รับราชการ", value: "รับราชการ" },
      { text: "รัฐวิสาหกิจ", value: "รัฐวิสาหกิจ" },
      { text: "รับจ้าง", value: "รับจ้าง" },
      { text: "ว่างงาน/ไม่มีอาชีพ", value: "ว่างงาน/ไม่มีอาชีพ" },
      { text: "อปพร.", value: "อปพร." },
      { text: "ทหาร", value: "ทหาร" },
      { text: "ลูกจ้างรัฐวิสาหกิจ", value: "ลูกจ้างรัฐวิสาหกิจ" },
      { text: "ลูกจ้างพลเรือน", value: "ลูกจ้างพลเรือน" },
      { text: "ลูกจ้างปกครองส่วนท้องถิ่น", value: "เผือก" },
      { text: "อื่นๆ", value: "อื่นๆ" },
    ],
  }),
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userPoliceWeb"))
    );
    this.getm_group();
    this.getAllData();
  },
  methods: {
     async getm_group() {
      const m_groupItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs`
      );
      this.m_groupItems = m_groupItems.data.data;
      console.log("this.m_groupItems", this.m_groupItems);
    },
    async getm_area(val) {
      console.log("val", val);
      const m_areaItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findAreaByGroup?group=${this.m_group}`
      );
      this.m_areaItems = m_areaItems.data.data;
      console.log("this.m_areaItems", this.m_areaItems);
    },
    async getm_province(val) {
      console.log("val", val);
      const m_provinceItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findProvinceByArea?area=${this.m_area}`
      );
      this.m_provinceItems = m_provinceItems.data.data;
      console.log("this.m_provinceItems", this.m_provinceItems);
    },
    clear() {
      this.suspect_sex = "";
      this.suspect_skin = "";
      this.suspect_job = "";
      this.date = new Date("2022-01-01").toISOString().substr(0, 10);
      this.date1 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
         this.m_area = "";
        this.m_group = "";
        this.m_province = "";
      this.getAllData();
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async getAllData() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reportForms/countByName?suspect_sex=${this.suspect_sex}&suspect_skin=${this.suspect_skin}&suspect_job=${this.suspect_job}&fromDate=${this.date}&toDate=${this.date1}&group=${this.m_group}&area=${this.m_area}&province=${this.m_province}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].number = parseInt(i) + 1;
      }

      console.log("items", this.items);
      this.loading = false;
    },
  },
};
</script>
